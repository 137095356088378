<template>
    <div class="tour">
        <BannerInnerPage />

        <div class="container tour__breads">
            <Breads :links="breadLinks" />
        </div>

        <div class="tour__content">
            <div class="container">
                <SectionTitle v-if="!preloader && Object.keys(tour).length" :title="tour.name" :hasButton="false"/>

                <div class="tour__info" v-if="Object.keys(tour).length">
                    <iframe class="tour__iframe" :src="storage(tour.source)"></iframe>
                    <div class="tour__description" v-if="tour.description" v-html="tour.description"></div>
                </div>
                <Preloader v-if="preloader" />
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>

import Footer from "@/components/Footer"
import BannerInnerPage from "@/components/BannerInnerPage"
import SectionTitle from "@/components/SectionTitle"

export default {
    components: {
        Footer,
        BannerInnerPage,
        SectionTitle,
    },

    data() {
        return {
            tour: {},
            breadLinks: [
                {
                    route: 'Tour',
                    text: ''
                },
            ],
            preloader: true
        }
    },

    async mounted() {
        await this.getTour()
    },

    methods: {
        getTour() {
            return this.api.article.tour(this.$route.params.uri).then(r => {
                this.tour = r.data
                this.breadLinks[0].text = r.data.name
            }).finally(() => {
                this.preloader = false
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    .tour {

        &__breads {
            padding-top: 30px;
        }

        &__content {
            padding: 70px 0;
            min-height: 35vh;
        }

        &__info {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__iframe {
            width: 950px;
            max-width: 100%;
            height: 520px;
        }
    }
</style>